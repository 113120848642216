import { Checkbox } from "@material-ui/core";

import "./Style.scss";

interface UsageDisclaimerPopupProps {
  handleCheckboxClick?: (isChecked: boolean) => void;
  isChecked?: boolean;
}
const UsageDisclaimerPopup = (props: UsageDisclaimerPopupProps) => {
  return (
    <>
      <div className="usage-disclaimer-popup">
        <ol>
          <li>
            <h3> Purpose and Nature of Gaiia</h3>
            <ul>
              <li>
                {" "}
                Gaiia is designed to provide users with general emotional
                support and information related to mental well-being. The
                responses and interactions are generated by algorithms and not
                by human professionals, lacking human intuition and empathy.
                Gaiia does not diagnose, treat, or offer solutions for serious
                mental health conditions or crises.
              </li>
            </ul>
          </li>
          <li>
            <h3>No Professional Advice or Doctor-Patient Relationship</h3>
            <ul>
              <li>
                {" "}
                The guidance and information provided by Gaiia are general in
                nature and not intended as professional advice. Users should not
                consider interactions with Gaiia as consultations with a
                licensed therapist or medical professional. No doctor-patient or
                therapist-client relationship is established through use.
              </li>
            </ul>
          </li>
          <li>
            <h3>Seek Immediate Professional Help for Severe Cases</h3>
            <ul>
              <li>
                If you or someone you know is experiencing severe mental health
                challenges or is in crisis, please seek immediate guidance from
                qualified professionals or emergency services. Do not rely on
                Gaiia for urgent or severe situations.
              </li>
            </ul>
          </li>
          <li>
            <h3>Age Restrictions</h3>
            <ul>
              <li>
                Gaiia is designed for users who are 16 years of age or older.
                Users below this age are prohibited from using the chatbot.
              </li>
            </ul>
          </li>
          <li>
            <h3>Data Privacy and User Interactions</h3>
            <ul>
              <li>
                By interacting with Gaiia and agreeing to this disclaimer, you
                consent to how we handle and store your data as outlined in our
                Privacy Policy. Additionally, by accepting this disclaimer, you
                agree to receive updates, offers, and relevant information about
                Gaiia. It's crucial for users to understand how their
                interactions with Gaiia are managed in terms of data privacy.
              </li>
            </ul>
          </li>
          <li>
            <h3> Accuracy and Completeness</h3>
            <ul>
              <li>
                While efforts are made to ensure the accuracy and completeness
                of information provided by Gaiia, there is no guarantee on the
                absolute accuracy or completeness of its responses.
              </li>
            </ul>
          </li>
          <li>
            <h3>User Responsibility</h3>
            <ul>
              <li>
                Users are responsible for their judgments, actions, and
                decisions based on their interactions with Gaiia.
              </li>
            </ul>
          </li>
          <li>
            <h3> Limitations of Liability</h3>
            <ul>
              <li>
                Neither the creators of Gaiia nor any affiliated parties will be
                responsible or liable for any decisions made or actions taken
                based on interactions with the chatbot.
              </li>
            </ul>
          </li>
          <li>
            <h3> External Links and Resources</h3>
            <ul>
              <li>
                Gaiia might provide links to external resources or websites. We
                are not responsible for the content, accuracy, or practices of
                these external sites.
              </li>
            </ul>
          </li>
          <li>
            <h3>Terms of Use</h3>
            <ul>
              <li>
                For a comprehensive understanding of the rules and policies of
                using Gaiia, please review our Terms and Conditions.
              </li>
            </ul>
          </li>
          <li>
            <h3>Updates to this Disclaimer</h3>
            <ul>
              <li>
                This disclaimer may be updated from time to time. We recommend
                users to revisit this page regularly to stay informed about any
                changes
              </li>
            </ul>
          </li>
        </ol>
        <div className="agreement-wrapper">
          <Checkbox
            onChange={(evt, isChecked: boolean) => {
              props!.handleCheckboxClick &&
                props.handleCheckboxClick(isChecked);
            }}
            checked={props?.isChecked}
          />
          <span>agree to usage disclaimer</span>
        </div>
      </div>
    </>
  );
};

export default UsageDisclaimerPopup;
